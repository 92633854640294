<template>
  <div class="credit-scoring-dashboard">
    <!-- Loading Dialog -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <!-- Page Header with Quick Actions -->
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="elevation-2 mb-3">
            <v-card-title class="headline">
              <span style="color: #172b4d">Credit Scoring Dashboard</span>
              <v-spacer></v-spacer>              
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>

      <!-- Quick Stats Cards -->
      <stats-summary-cards 
        :total-scores="totalScores"
        :active-risk-categories="activeRiskCategories"
        :monthly-reports="monthlyReports"
        :total-uploads="totalUploads"
      />

      <!-- Features Menu -->
      <CreditScoringFeaturesMenu />

      <!-- Period Selection Card -->
      <period-selector 
        v-model="periodSelection"
        :loading="loadingPeriodData"
        @load-data="loadPeriodData"
      />

      <!-- Key Metrics Summary Cards -->
      <key-metrics-summary 
        :report-summary="currentReportSummary"
        :formatters="formatters"
      />

      <!-- Charts Grid -->
      <dashboard-charts-grid
        :loading-charts="loadingCharts"
        :no-chart-data="noChartData"
        :current-report-summary="currentReportSummary"
        :chart-options="chartOptions"
        :selected-period-type="periodSelection.selectedPeriodType"
      />

      <error-dialog
        v-model="errorDialog.show"
        :title="errorDialog.title"
        :message="errorDialog.message"
        :details="errorDialog.details"
        :type="errorDialog.type"
        :suggestion="errorDialog.suggestion"
        :show-retry-button="errorDialog.showRetryButton"
        :show-action-button="errorDialog.showActionButton"
        :action-button-text="errorDialog.actionButtonText"
        :action-button-color="errorDialog.actionButtonColor"
        @retry="handleErrorRetry"
        @action="handleErrorAction"
      />
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import CreditScoringFeaturesMenu from '@/components/CreditScoringFeaturesMenu.vue';
import ErrorDialog from '@/components/ErrorDialogComponent.vue';
import creditScoringService from '@/services/creditScoringService';

// Import modular components
import StatsSummaryCards from './components/StatsSummaryCards.vue';
import PeriodSelector from './components/PeriodSelector.vue';
import KeyMetricsSummary from './components/KeyMetricsSummary.vue';
import DashboardChartsGrid from './components/DashboardChartsGrid.vue';

// Import chart configurations 
import { createChartOptions } from './chart-config/chartOptions';
// Import chart data service
import chartDataService from './services/chartDataService';

export default {
  name: 'CreditScoringDashboard',
  
  components: {
    CreditScoringFeaturesMenu,
    ErrorDialog,
    StatsSummaryCards,
    PeriodSelector,
    KeyMetricsSummary,
    DashboardChartsGrid
  },

  data() {
    return {
      loading: false,
      loadingPeriodData: false,
      loadingCharts: false,
      noChartData: false,
      totalScores: 0,
      activeRiskCategories: 0,
      monthlyReports: 0,
      totalUploads: 0,
      riskRatingCategories: [],
      reports: [],
      uploads: [],
      
      // Centralized period selection data
      periodSelection: {
        selectedPeriodType: 'year',
        selectedDate: new Date().toISOString().substr(0, 7),
        selectedYear: new Date().getFullYear(),
        selectedLoanType: []
      },
      
      // Chart data
      currentReportSummary: null,
      chartOptions: null,

      // Formatters for reuse
      formatters: {
        formatNumber: (value) => value.toLocaleString(),
        formatDecimal: (value) => value.toFixed(2),
        formatCurrency: (value) => {
          // Handle both object with $numberDecimal and direct number value
          let numValue = value;
          if (value && value.$numberDecimal) {
            numValue = parseFloat(value.$numberDecimal);
          }
          return numValue.toLocaleString(undefined, { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          });
        }
      },

      errorDialog: {
        show: false,
        title: 'Error',
        message: '',
        details: '',
        type: 'error',
        suggestion: '',
        showRetryButton: false,
        showActionButton: false,
        actionButtonText: 'Action',
        actionButtonColor: 'primary',
        retryCallback: null,
        actionCallback: null
      }
    };
  },

  created() {
    // Initialize the dashboard
    this.chartOptions = createChartOptions();
    
    // Load data in sequence
    this.initializeDashboard();
    
    // Set up sidebar state
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    this.SET_SIDEBAR_ROUTES([]);
  },

  methods: {
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SHOW_UNEXPECTED_ERROR_DIALOG",
      "SET_SIDEBAR_ROUTES"
    ]),

    async initializeDashboard() {
      this.loading = true;
      
      try {
        // Fetch risk rating categories first (smaller dataset)
        await this.fetchRiskRatingCategories();
        
        // Then fetch reports and uploads in parallel
        await Promise.all([
          this.fetchReports(),
          this.fetchUploads()
        ]);
        
        // Update summary stats
        this.updateSummaryStats();
        
        // Load initial period data after everything else is loaded
        this.$nextTick(() => {
          // Default to yearly view with all loan types
          this.periodSelection.selectedLoanType = [
            'Motor Vehicle', 'Real Estate', 'Cash Secured', 
            'Unsecured', 'Partially Secured', 'Chattels'
          ];
          
          // Load period data
          this.loadPeriodData();
        });
      } catch (error) {
        console.error('Error initializing dashboard:', error);
        this.showErrorDialog({
          title: 'Dashboard Initialization Error',
          message: 'Failed to load dashboard data',
          details: error.message,
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchRiskRatingCategories() {
      try {
        const response = await creditScoringService.fetchRiskRatingCategories();
        if (response.result && response.value) {
          this.riskRatingCategories = response.value;
          this.activeRiskCategories = response.value.filter(cat => cat.isActive).length;
        }
      } catch (error) {
        console.error('Error fetching risk rating categories:', error);
      }
    },

    async fetchReports() {
      try {
        const currentYear = new Date().getFullYear();
        const response = await creditScoringService.fetchReportsByYear(currentYear);
        if (response.result && response.value) {
          this.reports = response.value;
          this.monthlyReports = response.value.length;
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    },

    async fetchUploads() {
      try {
        const response = await creditScoringService.fetchUploads();
        if (response.result && response.value) {
          this.uploads = response.value;
          this.totalUploads = response.value.length;
        }
      } catch (error) {
        console.error('Error fetching uploads:', error);
      }
    },

    updateSummaryStats() {
      // Calculate total scores from the reports
      let totalScoresCount = 0;
      
      // Sum up all the total loans from all reports
      this.reports.forEach(report => {
        if (report.summary && report.summary.totalLoans) {
          totalScoresCount += report.summary.totalLoans;
        }
      });
      
      this.totalScores = totalScoresCount;
    },

    async loadPeriodData() {
      if (!this.periodSelection.selectedLoanType.length) {
        this.showErrorDialog({
          title: 'Selection Error',
          message: 'Please select at least one loan type',
          type: 'warning'
        });
        return;
      }

      this.loadingPeriodData = true;
      this.loadingCharts = true;
      this.noChartData = false;
      
      try {
        let reportSummary = null;
        
        // Fetch the report data based on the selected period
        if (this.periodSelection.selectedPeriodType === 'month') {
          // Parse the YYYY-MM format safely
          const [year, monthStr] = this.periodSelection.selectedDate.split('-');
          const monthNum = parseInt(monthStr);
          
          // Get month name from the array (correcting for 0-indexed array)
          const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];
          const month = monthNames[monthNum - 1];
          
          console.log(`Fetching report summary for ${month}/${year}`);
          
          // Use the fallback method that tries different month formats
          const response = await creditScoringService.fetchReportByMonthYearWithFallback(year, month);
          
          if (response.result && response.value) {
            // For backwards compatibility until backend is updated with summary endpoint
            // Exclude the loans array to reduce memory usage
            const { loans, ...summaryData } = response.value;
            reportSummary = {
              ...summaryData,
              month: month,
              year: year
            };
          } else {
            console.error('Error fetching monthly report:', response.error);
            this.showErrorDialog({
              title: 'Report Not Found',
              message: `No report found for ${month} ${year}`,
              details: response.error && response.error.message ? response.error.message : 'No report exists for the selected month and year',
              type: 'warning',
              suggestion: 'Please select a different month or check if reports have been generated for this period.'
            });
          }
        } else {
          // For yearly view, fetch reports for the year
          const response = await creditScoringService.fetchReportsByYear(this.periodSelection.selectedYear);
          if (response.result && response.value) {
            // Filter out loan data from each report to save memory
            const summaries = response.value.map(report => {
              const { loans, ...summaryData } = report;
              return summaryData;
            });
            
            reportSummary = { 
              reports: summaries,
              // Include the year for chart titles
              year: this.periodSelection.selectedYear 
            };
          }
        }
        
        if (!reportSummary || (this.periodSelection.selectedPeriodType === 'month' && !reportSummary.summary)) {
          // No data available for this period
          this.noChartData = true;
          this.resetCharts();
          return;
        }
        
        // Store the current report summary for reference
        this.currentReportSummary = reportSummary;

        // Check if there's any data for the selected loan types
        let hasDataForSelectedLoanTypes = false;

        if (this.periodSelection.selectedPeriodType === 'month') {
          // Check for monthly view
          if (reportSummary && reportSummary.summary) {
            const summary = reportSummary.summary;
            
            // Check if any filtered data exists for the selected loan types
            if (this.hasFilteredData(summary, this.periodSelection.selectedLoanType)) {
              hasDataForSelectedLoanTypes = true;
            }
          }
        } else {
          // Check for yearly view
          if (reportSummary && reportSummary.reports && reportSummary.reports.length > 0) {
            // Check if any report has data for selected loan types
            for (const report of reportSummary.reports) {
              if (report.summary && this.hasFilteredData(report.summary, this.periodSelection.selectedLoanType)) {
                hasDataForSelectedLoanTypes = true;
                break;
              }
            }
          }
        }

        // Set noChartData flag if no data for selected loan types
        this.noChartData = !hasDataForSelectedLoanTypes;
        
        // Update charts with the new data
        this.updateChartsWithNewData(reportSummary);
      } catch (error) {
        console.error('Error loading period data:', error);
        this.showErrorDialog({
          title: 'Data Loading Error',
          message: 'Failed to load period data',
          details: error.message,
          type: 'error'
        });
        this.noChartData = true;
        this.resetCharts();
      } finally {
        this.loadingPeriodData = false;
        this.loadingCharts = false;
      }
    },

    updateChartsWithNewData(data) {
      
      // Different processing for monthly vs. yearly data
      if (this.periodSelection.selectedPeriodType === 'month') {
        chartDataService.updateChartsWithMonthlyData(
          data, 
          this.chartOptions, 
          this.periodSelection.selectedLoanType
        );
      } else {
        chartDataService.updateChartsWithYearlyData(
          data.reports, 
          this.chartOptions, 
          this.periodSelection.selectedLoanType
        );
      }
    },
    
    resetCharts() {
      // Reset all chart options to their initial state
      this.chartOptions = createChartOptions();
      
      // Update chart titles to show 'No Data'
      const periodInfo = this.periodSelection.selectedPeriodType === 'month' 
        ? this.getFormattedMonthYear() 
        : `Year ${this.periodSelection.selectedYear}`;
      
      Object.keys(this.chartOptions).forEach(key => {
        if (this.chartOptions[key].title) {
          this.chartOptions[key].title.text += ` - ${periodInfo} (No Data)`;
        }
      });
    },

    getFormattedMonthYear() {
      if (!this.periodSelection.selectedDate) return '';
      
      // Parse the YYYY-MM format
      const [year, monthStr] = this.periodSelection.selectedDate.split('-');
      const monthNum = parseInt(monthStr);
      
      // Convert to month name
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      
      return `${monthNames[monthNum - 1]} ${year}`;
    },

    hasFilteredData(summary, selectedLoanTypes) {
      // Check various data objects to see if they have data for the selected loan types
      const dataObjects = [
        'stageDistribution', 'riskRatingDistribution', 'scoreDistribution',
        'eclByRiskGrade', 'eclByRiskDefinition', 'stageByRiskGrade', 'stageByRiskDefinition'
      ];
      
      for (const key of dataObjects) {
        if (summary[key]) {
          // For object type data
          if (typeof summary[key] === 'object' && !Array.isArray(summary[key])) {
            // Check if there are any keys (other than _id)
            const dataKeys = Object.keys(summary[key]).filter(k => k !== '_id' && !k.startsWith('_'));
            if (dataKeys.length > 0) {
              return true;
            }
          }
          // For array type data
          else if (Array.isArray(summary[key]) && summary[key].length > 0) {
            // If we have any items for the selected loan types
            const filteredItems = summary[key].filter(item => 
              !item.loanType || selectedLoanTypes.includes(item.loanType)
            );
            if (filteredItems.length > 0) {
              return true;
            }
          }
        }
      }
      
      return false;
    },

    showErrorDialog(options) {
      // Default values for options
      const errorTitle = options.title || 'Error';
      const errorMessage = options.message || 'An error occurred';
      const errorDetails = options.details || '';
      const errorType = options.type || 'error';
      const suggestion = options.suggestion || '';
      const showRetryButton = options.showRetryButton || false;
      
      // Make sure we have a valid action function if we're showing the action button
      const hasValidAction = typeof options.actionCallback === 'function';
      const showActionButton = options.showActionButton && hasValidAction;
      const actionButtonText = options.actionButtonText || 'Action';
      const actionButtonColor = options.actionButtonColor || 'primary';
      
      // Set error dialog properties
      this.errorDialog = {
        show: true,
        title: errorTitle,
        message: errorMessage,
        details: errorDetails,
        type: errorType,
        suggestion: suggestion,
        showRetryButton: showRetryButton,
        showActionButton: showActionButton,
        actionButtonText: actionButtonText,
        actionButtonColor: actionButtonColor,
        retryCallback: options.retryCallback || null,
        actionCallback: hasValidAction ? options.actionCallback : null
      };
    },
    
    handleErrorRetry() {
      if (typeof this.errorDialog.retryCallback === 'function') {
        this.errorDialog.retryCallback();
      }
    },

    handleErrorAction() {
      if (typeof this.errorDialog.actionCallback === 'function') {
        this.errorDialog.actionCallback();
      }
    },
  },
}
</script>

<style scoped>
.credit-scoring-dashboard {
  min-height: 100%;
  background-color: #f5f7f9;
}
</style>