<template>
  <v-flex xs12>
    <v-card class="elevation-2 mb-3">
      <v-card-title>Key Summary Metrics</v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm6 md3>
            <div class="metric-card primary--text">
              <div class="metric-title">Total Loans</div>
              <div class="metric-value">{{ formatters.formatNumber(summaryData.totalLoans) }}</div>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <div class="metric-card success--text">
              <div class="metric-title">Average Score</div>
              <div class="metric-value">{{ formatters.formatDecimal(summaryData.averageScore) }}</div>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <div class="metric-card warning--text">
              <div class="metric-title">Highest Score</div>
              <div class="metric-value">{{ formatters.formatDecimal(summaryData.highestScore) }}</div>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <div class="metric-card error--text">
              <div class="metric-title">Total ECL</div>
              <div class="metric-value">${{ formatters.formatCurrency(summaryData.totalEcl) }}</div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'KeyMetricsSummary',
  
  props: {
    reportSummary: {
      type: Object,
      default: null
    },
    formatters: {
      type: Object,
      required: true
    }
  },
  
  computed: {
    summaryData() {
      // Return summary data or default values
      if (!this.reportSummary) {
        return {
          totalLoans: 0,
          averageScore: 0,
          highestScore: 0,
          totalEcl: 0
        };
      }
      
      // For monthly view
      if (this.reportSummary.summary) {
        return this.reportSummary.summary;
      }
      
      // For yearly view (reportSummary.reports array)
      if (this.reportSummary.reports && this.reportSummary.reports.length > 0) {
        // Get the most recent report
        const sortedReports = [...this.reportSummary.reports].sort((a, b) => {
          const dateA = new Date(`${a.year}-${this.getMonthNumber(a.month)}-01`);
          const dateB = new Date(`${b.year}-${this.getMonthNumber(b.month)}-01`);
          return dateB - dateA; // Sort descending (newest first)
        });
        
        // Return summary from the most recent report
        if (sortedReports[0] && sortedReports[0].summary) {
          return sortedReports[0].summary;
        }
      }
      
      // Default values if no data found
      return {
        totalLoans: 0,
        averageScore: 0,
        highestScore: 0,
        totalEcl: 0
      };
    }
  },
  
  methods: {
    // Helper to convert month name to number for sorting
    getMonthNumber(monthName) {
      const months = {
        'January': '01', 'February': '02', 'March': '03', 'April': '04',
        'May': '05', 'June': '06', 'July': '07', 'August': '08',
        'September': '09', 'October': '10', 'November': '11', 'December': '12'
      };
      
      // Handle case variations
      const formattedMonth = Object.keys(months).find(
        month => month.toLowerCase() === monthName.toLowerCase()
      );
      
      return months[formattedMonth] || '01'; // Default to January if not found
    }
  }
}
</script>

<style scoped>
/* Key metrics styling */
.metric-card {
  padding: 16px;
  border-radius: 4px;
  background-color: #f5f5f5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: all 0.2s ease;
  margin-bottom: 10px;
}

.metric-card:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.metric-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.metric-value {
  font-size: 24px;
  font-weight: 700;
}
</style>