<template>
  <v-flex xs12>
    <v-card class="elevation-2 mb-3">
      <v-card-text>
        <v-layout row wrap align-center>
          <v-flex xs12 sm3>
            <v-select
              v-model="periodSelection.selectedPeriodType"
              :items="periodTypes"
              label="Period Type"
              @change="resetPeriodSelection"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm3>
            <v-menu
              v-if="periodSelection.selectedPeriodType === 'month'"
              ref="monthMenu"
              v-model="monthMenu"
              :close-on-content-click="false"
              :return-value.sync="periodSelection.selectedDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedMonth"
                  label="Select Month"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periodSelection.selectedDate"
                type="month"
                no-title
                scrollable
                @input="$refs.monthMenu.save(periodSelection.selectedDate)"
              >
              </v-date-picker>
            </v-menu>
            <v-select
              v-else
              v-model="periodSelection.selectedYear"
              :items="availableYears"
              label="Select Year"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm3>
            <v-select
              v-model="periodSelection.selectedLoanType"
              :items="loanTypes"
              label="Loan Type"
              multiple
              chips
              small-chips
              deletable-chips
            ></v-select>
          </v-flex>
          <v-flex xs12 sm3 class="text-xs-right">
            <v-btn 
              color="primary"
              @click="$emit('load-data')"
              :loading="loading"
              :disabled="!isValidSelection"
            >
              <v-icon left>search</v-icon>
              Load Data
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'PeriodSelector',
  
  props: {
    value: {
      type: Object,
      default: () => ({
        selectedPeriodType: 'year',
        selectedDate: new Date().toISOString().substr(0, 7),
        selectedYear: new Date().getFullYear(),
        selectedLoanType: []
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      monthMenu: false,
      periodTypes: [
        { text: 'Monthly View', value: 'month' },
        { text: 'Yearly View', value: 'year' }
      ],
      availableYears: Array.from(
        { length: 5 },
        (_, i) => new Date().getFullYear() - i
      ),
      loanTypes: [
        { text: 'Motor Vehicle', value: 'Motor Vehicle' },
        { text: 'Real Estate', value: 'Real Estate' },
        { text: 'Cash Secured', value: 'Cash Secured' },
        { text: 'Unsecured', value: 'Unsecured' },
        { text: 'Partially Secured', value: 'Partially Secured' },
        { text: 'Chattels', value: 'Chattels' }
      ]
    };
  },
  
  computed: {
    periodSelection: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    
    formattedMonth() {
      if (!this.periodSelection.selectedDate) return '';
      
      // Parse the YYYY-MM format safely, avoiding timezone issues
      const [year, monthStr] = this.periodSelection.selectedDate.split('-');
      
      // Convert to integers
      const yearNum = parseInt(year);
      const monthNum = parseInt(monthStr);
      
      // Use an array of month names instead of relying on Date object
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      
      // Get correct month name (monthNum is 1-12, array is 0-indexed)
      const monthName = monthNames[monthNum - 1];
      
      // Return the formatted month and year
      return `${monthName} ${yearNum}`;
    },
    
    isValidSelection() {
      if (this.periodSelection.selectedLoanType.length === 0) return false;
      if (this.periodSelection.selectedPeriodType === 'month' && !this.periodSelection.selectedDate) return false;
      if (this.periodSelection.selectedPeriodType === 'year' && !this.periodSelection.selectedYear) return false;
      return true;
    }
  },
  
  methods: {
    resetPeriodSelection() {
      // Reset selection when period type changes
      if (this.periodSelection.selectedPeriodType === 'month') {
        this.periodSelection.selectedYear = null;
        this.periodSelection.selectedDate = new Date().toISOString().substr(0, 7);
      } else {
        this.periodSelection.selectedDate = null;
        this.periodSelection.selectedYear = new Date().getFullYear();
      }
      this.periodSelection.selectedLoanType = [];
    }
  }
}
</script>