<template>
  <v-layout row wrap>
    <!-- Charts Row 1 -->
    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>Stage Distribution</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">pie_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.stageDistributionChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>Risk Rating Distribution</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">bar_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.riskRatingChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Charts Row 2 -->
    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>Score Distribution by Definition</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">bar_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.scoreDistributionChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>ECL by Risk Grade</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">show_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.eclByRiskGradeChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Charts Row 3 -->
    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>Stage by Risk Grade</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">stacked_bar_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.stageByRiskGradeChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 md6>
      <v-card class="elevation-2 mb-3">
        <v-card-title>ECL by Risk Definition</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">pie_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.eclByRiskDefinitionChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>

    <!-- Full Width Chart -->
    <v-flex xs12>
      <v-card class="elevation-2">
        <v-card-title>Stage Distribution by Risk Definition</v-card-title>
        <v-card-text>
          <div v-if="loadingCharts" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <div class="mt-2">Loading chart data...</div>
          </div>
          <div v-else-if="noChartData" class="text-center pa-4">
            <v-icon large color="grey lighten-1">stacked_bar_chart</v-icon>
            <div class="mt-2 grey--text">No data available for the selected period</div>
          </div>
          <chart-component v-else :options="chartOptions.stageByRiskDefinitionChartOptions"></chart-component>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';

drilldown(Highcharts);

export default {
  name: 'DashboardChartsGrid',
  
  components: {
    'chart-component': Chart
  },
  
  props: {
    loadingCharts: {
      type: Boolean,
      default: false
    },
    noChartData: {
      type: Boolean,
      default: false
    },
    chartOptions: {
      type: Object,
      required: true
    },
    currentReportSummary: {
      type: Object,
      default: null
    },
    selectedPeriodType: {
      type: String,
      default: 'month'
    }
  }
}
</script>

<style scoped>
.v-card {
  transition: all 0.3s ease-in-out;
}

.v-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Ensure charts maintain visibility */
.highcharts-container {
  min-height: 300px;
}

/* Loading and No Data states */
.text-center {
  text-align: center;
}

.pa-4 {
  padding: 16px;
}

.mt-2 {
  margin-top: 8px;
}

.grey--text {
  color: #757575 !important;
}
</style>