/* Responsible for processing data and updating chart options */
export default {
  /**
   * Update charts with monthly data
   * @param {Object} data - Report data for the selected month
   * @param {Object} chartOptions - Chart configuration options
   * @param {Array} selectedLoanTypes - Selected loan types for filtering
   */
  updateChartsWithMonthlyData(data, chartOptions, selectedLoanTypes) {
    if (!data || !data.summary) {
      // Reset charts if no data
      this.resetChartData(chartOptions);
      return;
    }

    const summary = data.summary;
    const periodLabel = this.getMonthYearLabel(data);

    // Check if we have any data after filtering by loan type
    let hasData = false;

    // Try to find any data in any chart
    if (summary.stageDistribution && Object.keys(summary.stageDistribution).length > 0)
      hasData = true;
    if (summary.riskRatingDistribution && Object.keys(summary.riskRatingDistribution).length > 0)
      hasData = true;
    if (summary.scoreDistribution && Object.keys(summary.scoreDistribution).length > 0)
      hasData = true;
    if (summary.eclByRiskGrade && Object.keys(summary.eclByRiskGrade).length > 0) hasData = true;
    if (summary.eclByRiskDefinition && Object.keys(summary.eclByRiskDefinition).length > 0)
      hasData = true;
    if (summary.stageByRiskGrade && Object.keys(summary.stageByRiskGrade).length > 0)
      hasData = true;
    if (summary.stageByRiskDefinition && Object.keys(summary.stageByRiskDefinition).length > 0)
      hasData = true;

    if (!hasData) {
      // Reset charts if no data after filtering
      this.resetChartData(chartOptions);
      return;
    }

    // Update chart titles with period information
    this.updateChartTitles(chartOptions, periodLabel, "Monthly");

    // Stage Distribution Chart
    if (summary.stageDistribution) {
      chartOptions.stageDistributionChartOptions.series[0].data = this.processObjectData(
        summary.stageDistribution,
      );
    }

    // Risk Rating Distribution Chart
    if (summary.riskRatingDistribution) {
      chartOptions.riskRatingChartOptions.series[0].data = this.processObjectData(
        summary.riskRatingDistribution,
      );
    }

    // Score Distribution Chart
    if (summary.scoreDistribution) {
      chartOptions.scoreDistributionChartOptions.series[0].data = this.processObjectData(
        summary.scoreDistribution,
      );
    }

    // ECL by Risk Grade Chart
    if (summary.eclByRiskGrade) {
      chartOptions.eclByRiskGradeChartOptions.series[0].data = this.processEclObject(
        summary.eclByRiskGrade,
      );
    }

    // ECL by Risk Definition Chart
    if (summary.eclByRiskDefinition) {
      chartOptions.eclByRiskDefinitionChartOptions.series[0].data = this.processEclObject(
        summary.eclByRiskDefinition,
      );
    }

    // Stage by Risk Grade Chart (Now as Pie Chart)
    if (summary.stageByRiskGrade) {
      chartOptions.stageByRiskGradeChartOptions.series[0].data = this.processStageByRiskGradeForPie(
        summary.stageByRiskGrade,
      );
    }

    // Stage by Risk Definition Chart
    if (summary.stageByRiskDefinition) {
      this.updateStackedChartFromObject(
        chartOptions.stageByRiskDefinitionChartOptions,
        summary.stageByRiskDefinition,
      );
    }
  },

  /**
   * Update charts with yearly data
   * @param {Array} reports - Array of reports for the selected year
   * @param {Object} chartOptions - Chart configuration options
   * @param {Array} selectedLoanTypes - Selected loan types for filtering
   */
  updateChartsWithYearlyData(reports, chartOptions, selectedLoanTypes) {
    if (!reports || !reports.length) {
      // Reset charts if no data
      this.resetChartData(chartOptions);
      return;
    }

    // Check if reports have any data
    let hasData = false;
    for (const report of reports) {
      if (report.summary) {
        // Check at least one data property
        if (
          report.summary.stageDistribution &&
          Object.keys(report.summary.stageDistribution).length > 0
        ) {
          hasData = true;
          break;
        }
      }
    }

    if (!hasData) {
      // Reset charts if no data after filtering
      this.resetChartData(chartOptions);
      return;
    }

    // Sort reports chronologically
    const sortedReports = [...reports].sort((a, b) => {
      const dateA = new Date(`${a.year}-${this.getMonthNumber(a.month)}-01`);
      const dateB = new Date(`${b.year}-${this.getMonthNumber(b.month)}-01`);
      return dateA - dateB;
    });

    const year = sortedReports[0].year;
    const periodLabel = `Year ${year}`;

    // Update chart titles with period information
    this.updateChartTitles(chartOptions, periodLabel, "Yearly");

    // Create aggregate summary from all reports
    const aggregatedData = this.aggregateYearlyDataFromObjects(sortedReports);

    // Update pie charts with aggregated data
    // Stage Distribution Chart
    if (aggregatedData.stageDistribution) {
      chartOptions.stageDistributionChartOptions.series[0].data = this.processObjectData(
        aggregatedData.stageDistribution,
      );
    }

    // Risk Rating Distribution Chart
    if (aggregatedData.riskRatingDistribution) {
      chartOptions.riskRatingChartOptions.series[0].data = this.processObjectData(
        aggregatedData.riskRatingDistribution,
      );
    }

    // Score Distribution Chart
    if (aggregatedData.scoreDistribution) {
      chartOptions.scoreDistributionChartOptions.series[0].data = this.processObjectData(
        aggregatedData.scoreDistribution,
      );
    }

    // ECL by Risk Grade Chart
    if (aggregatedData.eclByRiskGrade) {
      chartOptions.eclByRiskGradeChartOptions.series[0].data = this.processEclObject(
        aggregatedData.eclByRiskGrade,
      );
    }

    // ECL by Risk Definition Chart
    if (aggregatedData.eclByRiskDefinition) {
      chartOptions.eclByRiskDefinitionChartOptions.series[0].data = this.processEclObject(
        aggregatedData.eclByRiskDefinition,
      );
    }

    // Stage by Risk Grade (Pie Chart)
    chartOptions.stageByRiskGradeChartOptions.series[0].data =
      this.aggregateStageByRiskGradeForPie(sortedReports);

    // Stage by Risk Definition (Stacked Column)
    this.updateStackedTimeSeriesFromObjects(
      chartOptions.stageByRiskDefinitionChartOptions,
      sortedReports,
      "stageByRiskDefinition",
    );
  },

  /**
   * Process stageByRiskGrade data for pie chart
   * @param {Object} data - stageByRiskGrade data object
   * @returns {Array} - Data formatted for pie chart
   */
  processStageByRiskGradeForPie(data) {
    if (!data) return [];

    const result = [];

    Object.entries(data).forEach(([riskGrade, stageData]) => {
      // Skip metadata fields like _id
      if (riskGrade === "_id" || riskGrade.startsWith("_")) return;

      // Calculate total for this risk grade
      let total = 0;
      Object.entries(stageData).forEach(([stageName, count]) => {
        if (stageName !== "_id" && !stageName.startsWith("_")) {
          total += count;
        }
      });

      result.push({
        name: riskGrade,
        y: total,
      });
    });

    return result;
  },

  /**
   * Aggregate stageByRiskGrade data for yearly pie chart view
   * @param {Array} reports - Array of reports
   * @returns {Object} - Aggregated data for pie chart
   */
  aggregateStageByRiskGradeForPie(reports) {
    const result = {};

    reports.forEach((report) => {
      if (!report.summary || !report.summary.stageByRiskGrade) return;

      const data = report.summary.stageByRiskGrade;

      Object.entries(data).forEach(([riskGrade, stageData]) => {
        if (riskGrade === "_id" || riskGrade.startsWith("_")) return;

        if (!result[riskGrade]) {
          result[riskGrade] = { total: 0 };
        }

        // Sum all stages for this risk grade
        Object.entries(stageData).forEach(([stageName, count]) => {
          if (stageName !== "_id" && !stageName.startsWith("_")) {
            result[riskGrade].total += count;
          }
        });
      });
    });

    // Convert to pie chart format
    return Object.entries(result).map(([riskGrade, data]) => ({
      name: riskGrade,
      y: data.total,
    }));
  },

  /**
   * Process an object data structure (like stageDistribution) into series data
   * @param {Object} data - Object with keys as names and values as counts
   * @returns {Array} - Formatted for Highcharts
   */
  processObjectData(data) {
    if (!data) return [];

    // Handle case where data is already an array
    if (Array.isArray(data)) {
      return data.map((item) => ({
        name: item.name || item.stage || item.riskGrade || item.riskDefinition || "Unknown",
        y: item.count || item.value || 0,
      }));
    }

    // Handle object structure
    return Object.entries(data).map(([key, value]) => {
      // If value is a complex object with properties (like in eclByRiskGrade)
      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        return {
          name: key,
          y: value.count || 0,
        };
      }

      // Simple key-value pair
      return {
        name: key,
        y: value,
      };
    });
  },

  /**
   * Process ECL objects (which have a nested structure)
   * @param {Object} data - ECL data object
   * @returns {Array} - Formatted for Highcharts
   */
  processEclObject(data) {
    if (!data) return [];

    return Object.entries(data).map(([key, value]) => {
      let eclValue = 0;

      // Handle complex object with totalEcl
      if (typeof value === "object" && value !== null) {
        if (value.totalEcl) {
          // Handle $numberDecimal structure
          if (typeof value.totalEcl === "object" && value.totalEcl.$numberDecimal) {
            eclValue = parseFloat(value.totalEcl.$numberDecimal);
          } else {
            eclValue = value.totalEcl;
          }
        }
      } else {
        // Simple value
        eclValue = value;
      }

      return {
        name: key,
        y: eclValue,
      };
    });
  },

  /**
   * Update a stacked chart using object data (e.g., stageByRiskGrade)
   * @param {Object} chartOption - Chart configuration
   * @param {Object} data - Data object
   */
  updateStackedChartFromObject(chartOption, data) {
    if (!data) return;

    // Get all category names (risk grades or definitions)
    const categories = Object.keys(data);

    // Get all series names (stages)
    const allSeriesNames = new Set();

    // Find all stage keys across all categories
    Object.values(data).forEach((categoryData) => {
      Object.keys(categoryData).forEach((key) => {
        // Skip _id or other metadata
        if (key !== "_id" && !key.startsWith("_")) {
          allSeriesNames.add(key);
        }
      });
    });

    const seriesNames = Array.from(allSeriesNames);

    // Update categories
    chartOption.xAxis.categories = categories;

    // Create series data
    const seriesData = seriesNames.map((seriesName) => {
      const seriesPoints = categories.map((category) => {
        // Get the category data
        const categoryData = data[category];

        // Return the value for this series, or 0 if not present
        if (categoryData && categoryData[seriesName]) {
          return categoryData[seriesName];
        }
        return 0;
      });

      return {
        name: seriesName,
        data: seriesPoints,
      };
    });

    // Update series
    chartOption.series = seriesData;
  },

  /**
   * Update stacked chart for time series using object data
   * @param {Object} chartOption - Chart configuration
   * @param {Array} reports - Array of reports
   * @param {String} dataKey - Key to access data in the reports
   */
  updateStackedTimeSeriesFromObjects(chartOption, reports, dataKey) {
    // Get all months as categories
    const categories = reports.map((report) => {
      return report.month ? report.month.substring(0, 3) : "N/A";
    });

    // Update chart x-axis categories
    chartOption.xAxis.categories = categories;

    // Find all stage names across all reports
    const allStages = new Set();

    reports.forEach((report) => {
      if (report.summary && report.summary[dataKey]) {
        // For each risk category in the report
        Object.values(report.summary[dataKey]).forEach((categoryData) => {
          // Add each stage key (excluding metadata)
          Object.keys(categoryData).forEach((key) => {
            if (key !== "_id" && !key.startsWith("_")) {
              allStages.add(key);
            }
          });
        });
      }
    });

    const stageNames = Array.from(allStages);

    // If no stage names found, set empty series
    if (stageNames.length === 0) {
      chartOption.series = [];
      return;
    }

    // Create series data for each stage
    const seriesData = stageNames.map((stageName) => {
      // For each report, sum the values for this stage across all risk categories
      const data = reports.map((report) => {
        if (!report.summary || !report.summary[dataKey]) return 0;

        let stageTotal = 0;

        // Sum values for this stage across all risk categories
        Object.values(report.summary[dataKey]).forEach((categoryData) => {
          if (categoryData && categoryData[stageName]) {
            stageTotal += categoryData[stageName];
          }
        });

        return stageTotal;
      });

      return {
        name: stageName,
        data: data,
      };
    });

    // Update the chart series
    chartOption.series = seriesData;
  },

  /**
   * Aggregates data from all monthly reports into yearly totals
   * @param {Array} reports - Array of monthly reports
   * @returns {Object} - Aggregated yearly data
   */
  aggregateYearlyDataFromObjects(reports) {
    const aggregated = {
      stageDistribution: {},
      riskRatingDistribution: {},
      scoreDistribution: {},
      eclByRiskGrade: {},
      eclByRiskDefinition: {},
    };

    reports.forEach((report) => {
      if (!report.summary) return;

      // Aggregate Stage Distribution
      this.mergeObjectData(aggregated.stageDistribution, report.summary.stageDistribution);

      // Aggregate Risk Rating Distribution
      this.mergeObjectData(
        aggregated.riskRatingDistribution,
        report.summary.riskRatingDistribution,
      );

      // Aggregate Score Distribution
      this.mergeObjectData(aggregated.scoreDistribution, report.summary.scoreDistribution);

      // Aggregate ECL by Risk Grade
      this.mergeEclObjectData(aggregated.eclByRiskGrade, report.summary.eclByRiskGrade);

      // Aggregate ECL by Risk Definition
      this.mergeEclObjectData(aggregated.eclByRiskDefinition, report.summary.eclByRiskDefinition);
    });

    return aggregated;
  },

  /**
   * Merges simple object data (key-value pairs)
   * @param {Object} targetObj - Target object to merge into
   * @param {Object} sourceObj - Source object to merge from
   */
  mergeObjectData(targetObj, sourceObj) {
    if (!sourceObj) return;

    Object.entries(sourceObj).forEach(([key, value]) => {
      // If the value is a complex object and not a simple count
      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        // Handle objects with count properties
        const count = value.count || 0;

        if (targetObj[key]) {
          targetObj[key] += count;
        } else {
          targetObj[key] = count;
        }
      } else {
        // Simple numeric value
        if (targetObj[key]) {
          targetObj[key] += value;
        } else {
          targetObj[key] = value;
        }
      }
    });
  },

  /**
   * Merges ECL object data (objects with totalEcl properties)
   * @param {Object} targetObj - Target object to merge into
   * @param {Object} sourceObj - Source object to merge from
   */
  mergeEclObjectData(targetObj, sourceObj) {
    if (!sourceObj) return;

    Object.entries(sourceObj).forEach(([key, value]) => {
      if (!targetObj[key]) {
        // Initialize if not exists
        targetObj[key] = {
          count: 0,
          totalEcl: 0,
        };
      }

      // Extract count and ECL value
      let count = 0;
      let eclValue = 0;

      if (typeof value === "object" && value !== null) {
        count = value.count || 0;

        // Handle $numberDecimal ECL values
        if (value.totalEcl) {
          if (typeof value.totalEcl === "object" && value.totalEcl.$numberDecimal) {
            eclValue = parseFloat(value.totalEcl.$numberDecimal);
          } else {
            eclValue = value.totalEcl;
          }
        }
      }

      // Update the target object
      targetObj[key].count += count;
      targetObj[key].totalEcl += eclValue;
    });
  },

  /**
   * Update all chart titles with period information
   * @param {Object} chartOptions - All chart configuration options
   * @param {String} periodLabel - The period label (e.g., "January 2025" or "Year 2025")
   * @param {String} viewType - Either 'Monthly' or 'Yearly'
   */
  updateChartTitles(chartOptions, periodLabel, viewType) {
    // Update each chart's subtitle with the period information
    chartOptions.stageDistributionChartOptions.title.text = `Stage Distribution - ${periodLabel}`;
    chartOptions.riskRatingChartOptions.title.text = `Risk Rating Distribution - ${periodLabel}`;
    chartOptions.scoreDistributionChartOptions.title.text = `Score Distribution - ${periodLabel}`;
    chartOptions.eclByRiskGradeChartOptions.title.text = `ECL by Risk Grade - ${periodLabel}`;
    chartOptions.eclByRiskDefinitionChartOptions.title.text = `ECL by Risk Definition - ${periodLabel}`;
    chartOptions.stageByRiskGradeChartOptions.title.text = `Stage by Risk Grade - ${periodLabel}`;
    chartOptions.stageByRiskDefinitionChartOptions.title.text = `Stage by Risk Definition - ${periodLabel}`;

    // Enable titles to be visible
    Object.keys(chartOptions).forEach((key) => {
      if (chartOptions[key].title) {
        chartOptions[key].title.style = {
          fontSize: "14px",
          fontWeight: "500",
        };
      }
    });
  },

  /**
   * Get month and year label from the data
   * @param {Object} data - Report data
   * @returns {String} - Formatted period label
   */
  getMonthYearLabel(data) {
    if (!data) return "";

    // Try to get month and year from the data
    const month = data.month || "";
    const year = data.year || "";

    if (month && year) {
      return `${month} ${year}`;
    } else if (year) {
      return `Year ${year}`;
    } else {
      return "";
    }
  },

  /**
   * Convert month name to number for sorting
   * @param {String} monthName - Name of the month
   * @returns {String} - Month number as string (01-12)
   */
  getMonthNumber(monthName) {
    if (!monthName) return "01";

    const months = {
      january: "01",
      february: "02",
      march: "03",
      april: "04",
      may: "05",
      june: "06",
      july: "07",
      august: "08",
      september: "09",
      october: "10",
      november: "11",
      december: "12",
    };

    // Handle case variations
    const formattedMonth = monthName.toLowerCase();

    return months[formattedMonth] || "01"; // Default to January if not found
  },

  /**
   * Reset chart data to empty state
   * @param {Object} chartOptions - Chart options to reset
   */
  resetChartData(chartOptions) {
    // Reset pie charts
    const pieCharts = [
      "stageDistributionChartOptions",
      "riskRatingChartOptions",
      "scoreDistributionChartOptions",
      "eclByRiskGradeChartOptions",
      "stageByRiskGradeChartOptions",
      "eclByRiskDefinitionChartOptions",
    ];

    pieCharts.forEach((chartKey) => {
      if (chartOptions[chartKey] && chartOptions[chartKey].series) {
        chartOptions[chartKey].series[0].data = [];
      }
    });

    // Reset stacked column charts
    const stackedCharts = ["stageByRiskDefinitionChartOptions"];

    stackedCharts.forEach((chartKey) => {
      if (chartOptions[chartKey]) {
        chartOptions[chartKey].series = [];
        chartOptions[chartKey].xAxis.categories = [];
      }
    });
  },
};
