import Highcharts from "highcharts";

/**
 * Factory function to create all chart options
 * with default settings
 */
export function createChartOptions() {
  return {
    stageDistributionChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "Stage Distribution",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Loans",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    riskRatingChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "Risk Rating Distribution",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Loans",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    scoreDistributionChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "Score Distribution by Definition",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Loans",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    eclByRiskGradeChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "ECL by Risk Grade",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>${point.y:,.2f} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "ECL Amount",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    stageByRiskGradeChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "Stage by Risk Grade",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          // Added pie options
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Loans",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    eclByRiskDefinitionChartOptions: {
      chart: {
        type: "pie",
        height: 300,
      },
      title: {
        text: "ECL by Risk Definition",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>${point.y:,.2f} ({point.percentage:.1f}%)</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "ECL Amount",
          colorByPoint: true,
          data: [],
        },
      ],
      credits: {
        enabled: false,
      },
    },

    stageByRiskDefinitionChartOptions: {
      chart: {
        type: "column",
        height: 350,
      },
      title: {
        text: "Stage Distribution by Risk Definition",
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "Number of Loans",
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            color: "gray",
          },
        },
      },
      legend: {
        align: "right",
        verticalAlign: "top",
        borderWidth: 0,
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [],
      credits: {
        enabled: false,
      },
    },
  };
}